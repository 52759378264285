.container .table td[data-v-fae032fe] {
  font-size: 0.9rem;
}
[dir] .container .table th[data-v-fae032fe] {
  padding: 5px 10px !important;
}
[dir] .container .table-days .bg-success[data-v-fae032fe] {
  background-color: #57cc99 !important;
}
.container .table-days .text-success[data-v-fae032fe] {
  color: #57cc99 !important;
}
[dir] .container .table-days .bg-danger[data-v-fae032fe] {
  background-color: #f17374 !important;
}
.container .table-days .text-danger[data-v-fae032fe] {
  color: #f17374 !important;
}
@media print {
[dir] .container .table-days .bg-success[data-v-fae032fe] {
    background-color: #000 !important;
}
.container .table-days .text-success[data-v-fae032fe] {
    color: #000 !important;
}
[dir] .container .table-days .bg-danger[data-v-fae032fe] {
    background-color: #000 !important;
}
.container .table-days .text-danger[data-v-fae032fe] {
    color: #000 !important;
}
}
.container .table-days thead tr > th[data-v-fae032fe] {
  font-size: 11px;
}
[dir] .container .table-days thead tr > th[data-v-fae032fe] {
  padding: 10px !important;
}
[dir=ltr] .container .table-days thead tr > th[data-v-fae032fe]:first-child {
  border-radius: 6px 0px 0;
}
[dir=rtl] .container .table-days thead tr > th[data-v-fae032fe]:first-child {
  border-radius: 0px 6px 0px 0;
}
[dir=ltr] .container .table-days thead tr > th[data-v-fae032fe]:last-child {
  border-radius: 0px 6px 0;
}
[dir=rtl] .container .table-days thead tr > th[data-v-fae032fe]:last-child {
  border-radius: 6px 0px 6px 0;
}
.container .table-days tbody td[data-v-fae032fe] {
  font-size: 11px;
}
[dir] .container .table-days tbody td[data-v-fae032fe] {
  padding: 0px !important;
}
.container .table-days tbody td .d-cols[data-v-fae032fe] {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  width: 100%;
}
.container .table-days tbody td .d-cols .fist-col[data-v-fae032fe] {
  max-width: 50px;
  width: 100%;
}
[dir] .container .table-days tbody td .d-cols .fist-col[data-v-fae032fe] {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #d5d5d5;
  padding: 10px 5px;
  text-align: center;
  border-radius: 3px;
}
.container .table-days tbody td .d-cols .last-col[data-v-fae032fe] {
  width: 100%;
}
[dir] .container .table-days tbody td .d-cols .last-col[data-v-fae032fe] {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.container .table-basic tbody[data-v-fae032fe],
.container .table-basic thead[data-v-fae032fe] {
  color: #000;
}
.container .table-basic thead tr > th[data-v-fae032fe] {
  font-size: 11px;
}
[dir] .container .table-basic thead tr > th[data-v-fae032fe] {
  padding: 10px !important;
  border: solid 1px rgb(121, 121, 121);
  background: #b0b0b0 !important;
}
.container .table-basic tbody td[data-v-fae032fe] {
  font-size: 11px;
}
[dir] .container .table-basic tbody td[data-v-fae032fe] {
  border: solid 1px #d1d1d1;
  padding: 5px !important;
}